<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true">
				<el-form-item label="">
					<el-date-picker v-model="time" type="daterange"	align="right" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
				</el-form-item>
				<el-form-item>						
					<el-input v-model="username" placeholder="店铺账号查询" class="w120"></el-input>
				</el-form-item>
				<el-form-item>						
					<el-input v-model="name" placeholder="店铺名查询" class="w120"></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="is_handle" placeholder="处理状态" clearable class="w120">
						<el-option v-for="(item, i) in handles" :key="i" :label="item.text" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="nowtype" placeholder="问题类型" clearable class="w120">
						<el-option v-for="item in types" :key="item.value" :label="item.value" :value="item.type_id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="store_nickname" label="备注名" width="120" align="center"></el-table-column>
			<el-table-column prop="mobile" label="手机号" width="110" align="center"></el-table-column>
			<el-table-column prop="name" label="投诉店铺" width="110" align="center"></el-table-column>
			<el-table-column prop="username" label="店铺账号" width="100" align="center"></el-table-column>
			<el-table-column prop="createtime" label="投诉时间" width="150" sortable></el-table-column>
			<el-table-column prop="type_text" label="投诉类型" width="100" align="center"></el-table-column>
			<el-table-column prop="is_handle_text" label="处理状态" width="100" align="center">
				<template slot-scope="scope">					
					<strong class="mlr10" :class="scope.row.is_handle == 0 ? 'col-red1':scope.row.is_handle == 1 ? 'col-ls':'col-o1'">{{scope.row.is_handle_text}}</strong>
				</template>
			</el-table-column>
			<el-table-column prop="handle_content" label="处理方式" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column label="操作" width="160" align="center">
				<template slot-scope="scope">
					<template v-if="scope.row.is_handle != 1">
						<span class="cur-poi" @click="seeInfo(2, scope.row)">处理投诉</span>
						<span class="mlr10">|</span>
					</template>
					<span class="cur-poi" @click="seeInfo(1, scope.row)">查看详情</span>
				</template>
			</el-table-column>
		</el-table>
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="page"
				:page-sizes="pagesizes"
				:page-size="pagesize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-col>
		<el-dialog :title="setType == 1 ? '投诉详情' : '投诉处理'" :visible.sync="showInfo" width="700px" center>
			<el-form label-width="130px">
				<el-form-item label="投诉人信息：">
					{{tsInfo.store_nickname}}，【联系电话：{{tsInfo.mobile}}】,投诉时间：{{tsInfo.createtime}}
				</el-form-item>
				<div class="col-8 mb10">------------- 以下为申诉内容 -------------</div>
				<el-form-item label="申诉详情：">
					<div>{{tsInfo.shensu_content}}</div>
				</el-form-item>
				<el-form-item v-if="tsInfo.shensu_image && tsInfo.shensu_image.length > 0" label="申诉图片：">
					<div class="flex">
						<div class="mr10" v-for="(item,index) in tsInfo.shensu_image" :key="'ss_' + index "><img class="img1" :src="item" @click="imgurl = item"/></div>
					</div>
				</el-form-item>
				<div class="col-8 mb10">------------- 以下为发起投诉内容 -------------</div>
				<el-form-item label="投诉详情：">
					<div>【{{tsInfo.title}}<span v-if="tsInfo.plan_no"> - {{tsInfo.plan_no}}</span>】{{tsInfo.content}}</div>
				</el-form-item>
				<el-form-item v-if="tsInfo.images && tsInfo.images.length > 0" label="相关图片：">
					<div class="flex">
						<div class="mr10" v-for="(item,index) in tsInfo.images" :key="'ts_' + index "><img class="img1" :src="item" @click="imgurl = item"/></div>
					</div>
				</el-form-item>
				<div class="col-8 mb10">------------- 以下为用户与店主沟通内容 -------------</div>
				<!-- <el-form-item label="投诉时间：">
					{{tsInfo.createtime}}
				</el-form-item> -->
				<template v-if="tsInfo.item_list.length > 0">
                    <template v-for="(item1, index1) in tsInfo.item_list" >
                        <div :key="'c' + index1" v-if="item1.type == 1" class="flex p1 mb10">
                            <div class="mbox1 mr10">用户</div>
                            <div class="flex1 flex-column1">
                                <div class="mbox2 mb6">
                                    <div class="mb6">{{item1.content}}</div>
                                    <div v-if="item1.image"><img v-for="item2 in returnArr(item1.image)" class="img1 mr10" :src="item2" @click="imgurl = item2"></div>
                                </div>
                                <div class="c8 fs12">{{item1.addtime}}</div>
                            </div>
                        </div>
                        <div v-else :key="'c' + index1" class="flex p2 mb10">
                            <div class="flex1 mr10 flex-column2">
                                <div class="mbox2 mb6 tr">{{item1.content}}</div>
                                <div class="c8 fs12 tr">{{item1.addtime}}</div>
                            </div>
                            <div class="mbox1">店主</div>
                        </div>
                    </template>
                </template>
				<!-- <el-form-item label="处理状态：">
					<el-radio-group class="flex-align" v-model="tsInfo.is_handle">
						<el-radio v-for="item in handles" :label="item.value">{{item.text}}</el-radio>
					</el-radio-group>
				</el-form-item> -->
				<el-form-item  v-if="setType == 2" label="处理结果：">
					<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6}" v-model="tsInfo.handle_content" placeholder="请输入处理结果"></el-input>
					<!-- <div v-else class="bg01">{{tsInfo.item_list[tsInfo.item_list.length -1].content}}</div> -->
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="hideInfo">关 闭</el-button>
				<el-button v-if="setType == 2" type="primary" @click="submit">提交处理结果(即终止了该投诉)</el-button>
			</span>
		</el-dialog>
		<showimg v-if="imgurl" :imgurl="imgurl" @hideImg="imgurl = ''"></showimg>
	</section>
</template>

<script>
	let _t;
	import showimg from '@/components/showImg'
	import { getShensuList, doComplaint} from '../../api/api';
	import common from '@/utils/common';
	export default {
		components: { showimg },
		data() {
			return {
				time:[],
				username: '',
				name: '',
				order_status: '',
				pay_type: '',
				send_status: '',
				orderInfo: {},
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				dialogShow: false,
				typeList: [],
				handles: [
					{ value: 2, text: '未处理' },
					{ value: 1, text: '已处理' },
				],
				showInfo: false,
				tsInfo : {
					images: [],
					item_list: [
						{ content: '' }
					]
				},
				setType: 1,
				clCont: '',
				types: [],
				nowtype: '',
				is_handle: 2,
				imgurl: ''
			}
		},		
		created() {
			_t = this
			this.time = [common.getDay(-7), common.dateFormater('YYYY-MM-DD')];
			console.log('123', this.time )
			//获取列表
			this.getList(1);
		},
		methods: {
			returnArr(str){
                if(str) return str.split(',')
            },
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取列表
			getList(val) {
                if(val == '0') this.page = 1
				const para = {
					page: this.page,
					pagesize: this.pagesize,
					name: this.name,
					username: this.username,
					start_date: this.time[0],
					end_date: this.time[1],
					type: this.nowtype,
					is_handle: this.is_handle
				};
				this.listLoading = true;
				getShensuList(para).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.list;
						this.types = res.data.type_list;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			seeInfo(type, obj){
				_t.setType = type
				_t.showInfo = true
				_t.tsInfo = _t.deepCopy(obj)
			},
			hideInfo(){
				_t.showInfo = false,
				_t.tsInfo = {
					images: [],
					item_list: [[
						{ content: '' }
					]]
				}
			},
			submit(){
				const params = {
					id: _t.tsInfo.id,
					content: _t.tsInfo.handle_content,
					status: 3,
					is_handle: 1
				}
				doComplaint(params).then((res) => {
					if(res.code == 1){
						this.getList();
						this.hideInfo()
					}					
				});
			}
		},
	}
</script>
<style lang="less" scoped>
.el-form-item{ margin-bottom: 6px; }
.img1{ width: 64px; height: 64px; }
.mlr10{ margin: 0 10px; }
.mText{ width: 500px; border: none; background-color: #f7f7f7; padding: 10px; box-sizing: border-box; border-radius: 5px; }
.mbox1{ width: 36px; height: 36px; text-align: center; line-height: 40px; background-color: #f3f3f3; border-radius: 6px; font-size: 14px; border: 1px solid #eeeeee; }
.mbox2{ background-color: #f9f9f9; border-radius: 4px; font-size: 14px; border: 1px solid #eeeeee; padding: 6px; }
.pt10{ padding-top: 10px; }
.p1{ padding-right: 50px; }
.p2{ padding-left: 50px; }
.flex-column1 { display: flex; flex-direction: column; align-items: flex-start; }
.flex-column2 { display: flex; flex-direction: column; align-items: flex-end; }
</style>
